export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIGYELEM: az egyedi visszatérési kód nélkül a csomag nem azonosítható."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bankszámlaszám ezzel kezdődik: %s (példa: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ügyeljen arra, hogy az egyedi panaszszámot a termékhez mellékelje a csomagban. Az Ön egyedi panaszszáma:"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hozzászólás"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sérült termék"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üdvözletek"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helló!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Információ nem szükséges"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nincs ok"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miután visszaküldése megérkezett irodáinkba, e-mailt küldünk a panaszos űrlapon megadott címre, ahol tájékoztatjuk Önt a visszaküldési folyamat legfrissebb frissítéseiről."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelési szám"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetett a honlapunkon, és nem a szállítási szolgáltatás/futár"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A termék minősége"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termék csere"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termék visszaküldési eljárás"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termék visszaküldési folyamat"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A panasz oka"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérítés"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visszatérítési űrlap"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A termék visszaküldése gyors és egyszerű"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A termék visszaküldése gyors és egyszerű."])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lépés"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a panasz típusát"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el a csomagot"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el a csomagot a következő címre:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valami elromlott, próbálkozzon később, vagy vegye fel a kapcsolatot ügyfélszolgálat"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldje el az űrlapot"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A bankszámlaszám ezzel kezdődik: %s (példa: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visszaküldési eljárást most továbbították az Ön e-mail címére:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A visszaküldendő termékkel együtt írja be egyedi visszaküldési kódját a csomagba.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetés típusa"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amikor fióktelepünkben megkapjuk a csomagot, tájékoztatjuk Önt a panasz menetéről az Ön e-mail címére, amelyet a panasz űrlap benyújtásakor adott meg"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz termék"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rossz mennyiség"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A termék visszaküldéséhez kinyomtathatja a már kitöltött információkat"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ön már benyújtotta a visszaküldési kérelmet. Légy türelmes"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A mellékletben kapott egy PDF-fájlt a visszaküldési eljáráshoz."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön egyedi visszatérési száma:"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cím"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Város"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["panasz száma"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Példa"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utasítások"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irányítószám"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vevő"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feladó"])}
}